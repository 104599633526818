.TableTotal {
  border-top: 1px solid rgba(250, 250, 250, 0.05);
  padding: 8px 0;
  display: flex;
  margin-top: 12px;
  width: calc(100vw - 100px);

  &__total {
    font-weight: 700;
    font-size: 14px;
  }
  &__cell {
    font-size: 12px;
    min-width: 150px;
    width: 100%;
    text-align: center;
  }
}
