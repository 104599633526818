.Filters {
  border: 1px solid #232323;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.54);
  border-radius: 4px;
  padding: 12px;
  z-index: 100;
  position: relative;
  background: #141414;
  width: 232px;

  & .checkBoxContainer > div {
    margin: 6px 0;
  }

  & .ant-checkbox-inner {
    border-color: white;
    outline: none;
    outline: none !important;
    width: 10px;
    height: 10px;
    margin-bottom: 1px;

    &:after {
      border-color: #141414;
    }
  }

  & .ant-checkbox-checked > .ant-checkbox-inner {
    background-color: #dafd00 !important;
    border-color: #dafd00 !important;
    outline: none !important;
  }

  & .ant-checkbox-inner::after {
    width: 3.5px;
    height: 6px;
  }

  & .ant-checkbox:after {
    border-color: white !important;
  }

  & .filterCheckBoxText {
    font-size: 12px;
  }
}
