.editableCalendarInput {
  position: relative;
  margin: 0 8px;

  & > svg {
    position: absolute;
    top: 5.5px;
    left: 5px;
    z-index: 10;
  }

  & > input {
    background: rgba(250, 250, 250, 0.05);
    border-radius: 3px;
    outline: none;
    border: 0.5px solid transparent;
    max-width: 96px;
    width: 100%;
    height: 16px;
    font-size: 12px;
    text-align: center;

    &:focus {
      border: 0.5px solid rgba(250, 250, 250, 0.4);
    }
  }
  &.included {
    & > input {
      color: #8388ff;
    }
  }
}

.IncomeCalendarCell {
  margin-bottom: -4px;
  width: max(80px, calc(100% / 7));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__day {
    color: #dafd00;
    font-size: 12px;
    font-weight: 600;
  }
}
