.listButtonText {
  font-size: 12px;
  font-weight: 400;
}
.listButtonGreen {
  font-weight: 600;
  font-size: 16px;
  color: rgba(119, 190, 29, 0.66);
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover > text {
    color: #dafd00 !important;
  }
}
.listButtonRed {
  font-weight: 600;
  font-size: 16px;
  color: rgba(217, 40, 40, 0.66);
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover > text {
    color: #dafd00 !important;
  }
}
.listButtonsContainer {
  padding: 24px 24px 16px 8px;
  gap: 24px;
  display: flex;
  flex-wrap: wrap;
}
.listWrapper {
  background-color: rgba(18, 18, 18, 1);
  height: 60%;
  border-radius: 16px;
  overflow: auto;
}
.listSortingText {
  font-size: 12px;
  font-weight: 400;
  color: rgba(250, 250, 250, 0.4);
}
.listSortingButton {
  & * {
    background-color: rgb(18, 18, 18) !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  &.ant-select-open * {
    color: #dafd00 !important;
    fill: #dafd00 !important;
  }
}
.listSortingContainer {
  margin-top: 24px;
  padding: 24px 16px 24px 16px;
  background-color: rgba(18, 18, 18, 1);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.listBorderlessButton {
  background-color: transparent;
  border: 0px;
  cursor: pointer;
}
.listSelectText {
  font-size: 12px;
  font-weight: 400;
  color: rgba(250, 250, 250, 1);
}
