.AdminFormSelect {
  display: flex;
  width: 261px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  &__name {
    font-size: 12px;
    font-weight: 300;
  }

  &__select {
    width: 144px;
    border-bottom: 1px solid grey;

    & * {
      background-color: rgb(18, 18, 18) !important;
      border-color: transparent !important;
      box-shadow: none !important;
      font-family: 'Inter', sans-serif !important;
    }
    & .ant-select-selector {
      padding: 0 2px !important;
    }
    &.ant-select-open * {
      color: #dafd00 !important;
      fill: #dafd00 !important;
    }
    & .ant-select-selection-item {
      font-size: 12px;
    }
    & .ant-select-selection-placeholder {
      color: grey;
      position: relative;
      font-size: 12px;
    }
  }

  &__error {
    color: #d92828;
    font-size: 8px;
    font-weight: 300;
    line-height: 10px;
    max-width: 144px;
  }
}
