.HomePageCards {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  &__wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
    gap: 24px;
  }
}
