.storeName {
  font-size: 18px;
  font-weight: 400;
  color: rgba(250, 250, 250, 0.4);
  padding-right: 8px;
}
.exportAndNameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.weekDaysTax {
  font-size: 12px;
  font-weight: 400;
  color: rgba(250, 250, 250, 0.4);
  text-align: left;
  height: 40px;
  padding-top: 13px;
  width: 100%;
  padding-left: 24px;
}
.weekDaysIncome {
  font-size: 12px;
  font-weight: 400;
  color: rgba(250, 250, 250, 0.4);
  text-align: left;
  height: 40px;
  padding-top: 13px;
  margin-top: 24px;
  background-color: rgba(131, 136, 255, 0.03);
  width: 100%;
  padding-left: 24px;
}
.weekDays {
  font-size: 12px;
  font-weight: 400;
  color: rgba(250, 250, 250, 0.4);
  text-align: center;
  width: 100%;
  min-width: 96px;
}
.weekDaysContainer {
  width: max(672px, calc(74vw - 96px));
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.days {
  font-size: 12px;
  font-weight: 600;
  color: rgba(131, 136, 255, 1);
  width: 88px;
  text-align: center;
  cursor: pointer;
}
.weekDaysTitle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 57px;
}
.sum {
  font-size: 12px;
  font-weight: 400;
  color: rgba(250, 250, 250, 1);
  width: 88px;
  text-align: center;

  &.included {
    color: #8388ff;
  }
}
.incomeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.width30 {
  width: 30%;
}
.incomeWrapper {
  padding: 0px 16px 24px 16px;
  border-radius: 0px 0px 16px 16px;
  display: flex;
  flex-direction: column;
  height: 80%;
  overflow: auto;
}
.incomeBodyContainer {
  display: flex;
  align-items: center;
  padding: 24px 0px 24px 96px;
  margin-top: 24px;
  border-radius: 16px 16px 0px 0px;
}
.rowsIncome {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-right: 57px;
  margin-top: 24px;
}
.nameWeek {
  width: 10%;
  padding-bottom: 120px;
}
.rowsIncomeWrapper {
  width: 10%;
  display: flex;
  flex-direction: column;
}
.width80 {
  width: 80%;
}
.taxTitle {
  text-align: left;
  height: 40px;
  padding-top: 13px;
}
.dayIncomeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}
.sumIncomeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  background-color: rgba(131, 136, 255, 0.03);
  height: 40px;
}
.incomeCalendarRow {
  display: flex;
  border-top: 1px solid rgba(250, 250, 250, 0.05);
  height: 90px;
  width: calc(max(672px, calc(74vw - 96px)) + 160px);

  &__first {
    display: flex;
    min-width: 96px;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    justify-content: flex-end;
    padding: 12px;

    &--included {
      font-size: 10px;
      color: rgba(131, 136, 255, 0.72);
    }
    &--excluded {
      font-size: 10px;
      color: rgba(255, 255, 255, 0.74);
    }
  }

  &__data {
    display: flex;
    min-width: max(672px, calc(74vw - 96px));
  }
  &__nameWeek {
    min-width: 96px;
  }
}

.IncomeHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  &__info {
    display: flex;
    gap: 12px;
    align-items: center;

    & .text {
      color: rgba(250, 250, 250, 0.4);
      font-size: 12px;
    }
    & .name {
      color: rgba(250, 250, 250, 0.72);
      font-size: 12px;
    }
  }
}
