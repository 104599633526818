.adminModal {
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    & > * {
      width: 120px !important;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    background-color: rgb(18, 18, 18);
    padding: 24px 32px;
    border-radius: 8px;

    & > h3 {
      font-size: 16px;
      font-weight: 600;
      color: #fafafa;
      margin-bottom: 32px;
    }

    & > h5 {
      font-size: 10px;
      color: #fafafa66;
    }
  }
}
