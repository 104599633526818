.TopCard {
  width: 260px;

  &__tax {
    font-size: 12px;
    color: rgba(250, 250, 250, 0.72);
    margin: 16px 0;
    cursor: pointer;
  }

  &__title {
    font-size: 16px;
    line-height: 132%;
    text-align: center;
  }

  &__mallName {
    font-size: 12px;
    line-height: 132%;
    color: rgba(250, 250, 250, 0.8);
    width: 100%;
    text-align: center;
    margin-top: 4px;
  }

  &__data {
    display: flex;
    justify-content: space-between;
    padding: 16px 22px;
    width: 100%;

    & > div {
      display: flex;
    }

    &__columns {
      display: flex;
      flex-direction: column;
    }

    &__date {
      font-size: 11px;
      color: rgba(250, 250, 250, 0.4);
      margin-top: 7px;
      min-width: 100px;
    }

    &__numbers {
      font-size: 12px;
      text-align: center;
      margin-top: 6px;
      width: 90px;
    }

    &__percents {
      font-size: 12px;
      margin-left: 16px;
      margin-top: 6px;
    }
  }
}

@media (max-width: 680px) {
  .TopCard {
    width: calc(100vw - 128px);
  }
}
