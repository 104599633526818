.GrayButton {
  width: 140px;
  height: 40px !important;
  background-color: rgba(250, 250, 250, 0.05) !important;
  border-width: 0 !important;
  border-radius: 6px !important;
  font-size: 12px !important;
  text-transform: none !important;
  color: #fafafa !important;
  cursor: pointer;

  &:hover {
    background: #fafafa33 !important;
  }
}
