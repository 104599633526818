.mainWrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 400px;
}
.titleLogin {
  font-size: 48px;
  font-weight: 700;
}
.inputsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 280px;
  width: 512px;
}
.inputEmailContainer {
  display: flex;
  flex-direction: column;
}
.inputPasswordContainer {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.inputTitle {
  font-size: 16px;
  font-weight: 400;
}
.inputPassword {
  margin-top: 8px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 6px;
  color: rgba(250, 250, 250, 0.52);
  height: 40px;
  width: 400px;
}
.inputEmail {
  margin-top: 8px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 6px;
  color: rgba(250, 250, 250, 0.52);
  height: 40px;
  width: 400px;
}
.buttonLogin {
  width: 188px !important;
  height: 40px !important;
  margin-top: 20px !important;
  background-color: rgba(250, 250, 250, 0.05) !important;
  border-width: 0 !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  text-transform: none !important;
  cursor: pointer;
}
.buttonLogin:hover {
  background-color: rgba(250, 250, 250, 0.2);
}
.error {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
  font-size: 16px;
  font-weight: 400;
  width: 78%;
}
.eyeIconContainerLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(20, 20, 20, 1);
  position: absolute;
  margin-left: 370px;
  margin-top: 38px;
}
