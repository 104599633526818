.AdminFormInput {
  display: flex;
  width: 261px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 8px;

  &__name {
    font-size: 12px;
    font-weight: 300;
  }

  &__input {
    width: 144px;

    &.MuiTextField-root {
      border-bottom: 1px solid grey;
    }

    & input {
      color: #fafafa;
      padding: 4px 2px;
      font-size: 12px;
    }

    &.phone input {
      letter-spacing: 1px;
    }

    &.password {
      &.hidden input {
        -webkit-text-security: disc;
        text-security: disc;
      }
    }

    & *:after {
      display: none;
    }
  }

  & .eye {
    position: absolute;
    right: 0;
    cursor: pointer;
    top: 0;
  }

  &__error {
    color: #d92828;
    font-size: 8px;
    font-weight: 300;
    line-height: 10px;
    max-width: 144px;
  }
}
