.mall-component {
  display: flex;
  align-items: center;
  user-select: none;
  background-color: rgb(18, 18, 18);
  border-radius: 4px;
  overflow: hidden;

  &__arrow {
    background: none;
    border: none;
    color: #fafafa;
    cursor: pointer;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(250, 250, 250, 0.05);
    }
  }

  &__selected {
    flex-grow: 1;
    text-align: center;
    padding: 8px 16px;
    color: #fafafa;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    min-width: 120px;
  }
}

.listMonths {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(18, 18, 18);
  border-radius: 4px;
  padding: 4px;
  margin-top: 16px;
}

.listBorderlessButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(250, 250, 250, 0.05);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.listMonthsTitleYellow {
  color: #DAFD00;
  font-weight: 500;
}

.listMonthsTitleWhite {
  color: #fafafa;
  font-weight: 500;
}

.listMonthsTitleGrey {
  color: rgba(250, 250, 250, 0.4);
  font-weight: 500;
}