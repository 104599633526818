.ConfirmModal {
  display: flex;
  flex-direction: column;
  background-color: rgb(18, 18, 18);
  padding: 24px 32px;
  border-radius: 8px;
  width: 428px;
  height: 154px;
  justify-content: center;
  align-items: center;

  &__text {
    text-align: center;
    min-width: 270px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    &.center {
      justify-content: center;
    }

    & > * {
      width: 120px;
      height: 40px !important;
      margin-top: 20px !important;
      background-color: rgba(250, 250, 250, 0.05) !important;
      border-width: 0 !important;
      border-radius: 6px !important;
      font-size: 16px !important;
      text-transform: none !important;
      color: white !important;
      cursor: pointer;

      &.MuiLoadingButton-loading {
        color: transparent !important;
      }
    }
  }
}
