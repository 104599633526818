.listMonths {
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.listMonthsTitleWhite {
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}
.listMonthsTitleWhite:hover {
  color: rgba(218, 253, 0, 0.55);
}
.listMonthsTitleGrey {
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.53);
}
.listMonthsTitleYellow {
  font-size: 16px;
  font-weight: 600;
  color: rgba(218, 253, 0, 1);
}

@media (max-width: 600px) {
  .listMonths {
    gap: 8px;
  }
}
