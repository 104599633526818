.Input {
  width: 180px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid #fafafa0d;
  position: relative;
  margin-right: 16px;

  & .anticon {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 2;

    & * {
      color: #fafafa0d;
    }
  }

  & input {
    width: 100%;
    height: 100%;
    padding-left: 32px;
    padding-right: 8px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent !important;
    border: none;
    outline: none;
  }
}
