.AdminTablePagination {
  display: flex;
  gap: 6px;
  margin: 24px 0;

  & * {
    color: #fafafa66;
    font-size: 10px;
    line-height: 28px;
  }

  & .paginationButtonsWrapper {
    display: flex;
    gap: 4px;
  }

  & .paginationButton {
    max-width: 22px;
    min-width: 0;
    height: 28px;
    padding-top: 9px;

    & span {
      max-width: 16px;
    }

    &.active {
      background: #fafafa0d;
    }
  }

  & button {
    color: #fafafa66;
    font-size: 10px;
    text-transform: none !important;
    cursor: pointer;
    border: 0.5px solid #fafafa0d;
    height: 28px;
    padding-top: 9px;

    &.Mui-disabled {
      color: #fafafa66;
    }

    & > span {
      margin-top: -2px;
    }
  }
}
