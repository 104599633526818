.adminTable {
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 16px 0;

  & .ant-table-pagination {
    justify-content: flex-start !important;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    &__rtl {
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
      direction: rtl;
    }
  }

  &__inner {
    min-height: 610px;

    & *,
    & *:hover {
      background-color: rgb(18, 18, 18) !important;
      color: white;
      border-color: rgba(250, 250, 250, 0.051) !important;
    }
  }

  & a:hover {
    color: #dafd00;
  }

  &__grey {
    color: grey;
  }

  & .ant-table-row {
    height: 40px;
  }

  & .ant-table-tbody > tr > td {
    padding: 8px 12px;
    line-height: 38px;

    & > div {
      height: 40px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  & .ant-table-thead > tr > th {
    padding: 0 12px;
    color: grey;
    height: 40px;

    & * {
      color: grey;
    }

    &::before {
      display: none !important;
    }
  }
}
