.Tables {
  max-width: 100vw;

  & > div {
    position: absolute;
    background: rgb(18, 18, 18);
    transition: height 0.5s ease-in-out;

    &.opacityGray {
      opacity: 0.6;
    }
  }

  &:not(.loading) > div {
    transition: top 1s ease-in-out;
  }
}
