.homeContainer {
  height: 100%;
  width: calc(100% - 128px);
  display: flex;
  flex-direction: column;
  border-radius: 24px 0px 0px 0px;
  margin-right: 64px;
}
.homeMainContainer {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.chartsTitle {
  font-size: 12px;
  font-weight: 400;
  color: rgba(250, 250, 250, 0.4);
}
.chartsButton {
  border-width: 0px;
  background-color: transparent;
  padding-left: 20px;
  cursor: pointer;
}
.chartsContainer {
  width: 102px;
  background-color: rgba(20, 20, 20, 1);
  height: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addTable {
  background-color: rgba(250, 250, 250, 0.05);
  border-radius: 8px;
  border: 0.5px solid rgba(250, 250, 250, 0.05);
  width: 110px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.addTableText {
  color: rgba(250, 250, 250, 1);
  font-size: 12px;
  font-weight: 400;
}
