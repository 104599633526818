#root,
.app-wrapper {
  height: 100vh;
  font-family: 'Inter', sans-serif;
  font-style: normal;
}

html,
body {
  width: calc(100vw - 8px);
  height: 100vh;
  background: rgb(18, 18, 18);
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #6b6b6b #121212;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-corner {
  background-color: #121212;
}
*::-webkit-scrollbar-track {
  background: #121212;
}

*::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
  border-radius: 12px;
  width: 10px;
}
.mallContainer {
  height: 100%;
  width: calc(100% - 62px);
  padding: 24px;
  border-radius: 24px 0px 0px 0px;
}
.mainContainer {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 60px);
  margin-left: 65px;
}

*,
*::before,
*::after {
  color: white;
}

.MuiModal-root {
  justify-content: center;
  align-items: center;
  display: flex;
}

.AdminInfo {
  width: 100%;

  &__container {
    background: #141414;
    width: 438px;
    margin: 24px auto;
    border-radius: 16px;
    margin-top: 24px;
    border: 1px solid #fafafa0d;
    gap: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px 0;

    &__controls {
      position: absolute;
      display: flex;
      gap: 8px;
      top: 16px;
      right: 16px;

      & .anticon * {
        color: grey;
      }
    }

    &__wrapper {
      display: flex;
      width: 56%;

      & .grey {
        color: #fafafa66;
        font-size: 12px;
        min-width: 160px;
      }

      & .white {
        color: #fafafa;
        font-size: 12px;
        min-width: 154px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.EditForm {
  width: 100%;
  padding: 20px;

  &__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    & > * {
      width: 192px !important;
    }
  }
}

.ant-select-item-option-selected {
  background-color: #6b6b6b !important;
}

.Toastify__toast {
  width: 262px;
  height: 72px;
  background-color: #141414;
  border-radius: 8px;
}

.success-notification {
  border: 0.5px solid #77be1d;
}

.error-notification {
  border: 0.5px solid #d92828;
}

.Toastify__progress-bar {
  display: none;
}

.Toastify__toast-icon {
  display: none;
}

.ant-picker-dropdown {
  z-index: 2000 !important;

  & .ant-picker-panel-container {
    background-color: #141414;
  }

  & th {
    color: grey !important;
  }
}
