.ToggleableEye {
  cursor: pointer;
  height: 17px;

  &.disabled * {
    color: #797979 !important;
  }

  &:hover * {
    color: #dafd00 !important;
  }
}
