.ExportProgress {
  position: fixed;
  top: 48px;
  right: 48px;
  width: 262px;
  height: 72px;
  background-color: #141414;
  border-radius: 8px;
  border: 0.5px solid #77be1d;

  &__text {
    height: 76px;
    padding: 6px;
    font-size: 14px;
    text-align: center;
    padding-top: 12px;
  }

  & #progressLine {
    width: 80%;
    position: relative;
    background-color: red;
    height: 4px;
    margin-left: 10%;
    bottom: 20px;

    & #doneProgress {
      position: absolute;
      background-color: greenyellow;
      height: 4px;
      left: 0;
    }
  }
}
