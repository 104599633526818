.AdminEditFormDate {
  width: 100%;
  margin-bottom: 8px;

  &__name {
    font-size: 12px;
    font-weight: 300;
    color: #fafafad6;
    margin-bottom: 8px;
  }

  &__date-input {
    width: 100%;
    height: 32px;

    border: 1px solid rgb(62, 62, 62) !important;

    background-color: rgb(18, 18, 18) !important;
    box-shadow: none !important;
    font-family: 'Inter', sans-serif !important;
    padding: 4px 14px;

    & * {
      background-color: rgb(18, 18, 18) !important;
      border-color: transparent !important;
      box-shadow: none !important;
      font-family: 'Inter', sans-serif !important;
    }

    & input {
      color: #fafafa !important;
      font-size: 12px !important;

      &::placeholder {
        color: #727272 !important;
        font-size: 11.5px;
        font-weight: 600;
      }
    }
  }

  &__error {
    color: #d92828;
    font-size: 8px;
    font-weight: 300;
    line-height: 10px;
  }
}
