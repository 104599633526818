.leftMenuWrapper {
  position: fixed;
  left: 0;
  height: 100%;
  width: 65px;
  overflow: hidden;
}

.containerMenu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hideIcon {
  width: 100%;
  display: flex;
}

.wrapperMenu {
  display: flex;
  justify-content: space-between;
  padding-top: 26px;
  width: 100%;
}

.iconContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.buttonWithPadding {
  border-width: 0;
  background-color: transparent;
  padding-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  width: 100%;
}

.titlesContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.titlesWhite {
  font-size: 12px;
  font-weight: 600;
  color: rgba(250, 250, 250, 1);
}

.titlesGrey {
  font-size: 12px;
  font-weight: 600;
  color: rgba(250, 250, 250, 0.4);
}

.titlesGrey:hover {
  color: rgba(218, 253, 0, 0.55);
}
