.AdminEditFormSelect {
  width: 100%;
  margin-bottom: 8px;

  &__name {
    font-size: 12px;
    font-weight: 300;
    color: #fafafad6;
    margin-bottom: 8px;
  }

  &__select {
    width: 100%;
    border: 1px solid rgb(62, 62, 62);
    border-radius: 4px;

    & * {
      background-color: rgb(18, 18, 18) !important;
      border-color: transparent !important;
      box-shadow: none !important;
      font-family: 'Inter', sans-serif !important;
    }
    & .ant-select-selector {
      padding: 0 12px !important;
      height: 32px !important;
    }
    &.ant-select-open * {
      color: #dafd00 !important;
      fill: #dafd00 !important;
    }
    & .ant-select-selection-item {
      font-size: 12px;
    }
    & .ant-select-selection-placeholder {
      color: #727272;
      position: relative;
      font-size: 11.5px;
      font-weight: 600;
    }
  }

  &__error {
    color: #d92828;
    font-size: 8px;
    font-weight: 300;
    line-height: 10px;
  }
}
