.DateSelector {
  padding: 28px 32px;
  background: #121212;
  border: 0.5px solid rgba(250, 250, 250, 0.05);
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.54);
  border-radius: 16px;
  width: 450px;
  height: 194px;

  &__presets {
    display: flex;
    gap: 22px;
    margin-bottom: 28px;

    & > div {
      font-size: 12px;
      cursor: pointer;

      &:hover {
        color: #dafd00;
      }
    }
  }

  &__dates {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__border {
    border: 1px solid rgba(250, 250, 250, 0.05);
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 12px;
  }

  &__line {
    width: 16px;
    height: 2px;
    border-radius: 1px;
    background-color: white;
  }

  &__wrapper {
    display: flex;
    gap: 22px;
  }
  &__bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 12px 0;
    padding-right: 8px;
  }
}

.NumberInput {
  display: flex;
  flex-direction: column;
  width: 22px;

  & input {
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 12px;
    text-align: center;
  }

  & > div {
    width: 22px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &:hover svg * {
      color: #dafd00;
    }
  }
}

.ant-popover-inner {
  padding: 0 !important;
  background-color: transparent !important;
}
