.TableMallComponent {
  &__empty {
    &__mallName {
      height: 42px;
    }
  }

  &__mallName {
    font-weight: 500;
    font-size: 14px;
    height: 42px;
    padding: 11px 0;
    line-height: 40px;
    display: flex;
    cursor: pointer;
    position: sticky;
    top: 100px;
    z-index: 9;
    width: 140px;
    background-color: rgb(18, 18, 18);
    align-items: center;
    gap: 8px;

    & *.hidden {
      color: rgba(250, 250, 250, 0.4);
    }

    & svg:not(.triangle) {
      left: -18px;
      position: absolute;
      top: 13px;
    }
    & svg.triangle {
      left: 130px;
      position: absolute;
      top: 18px;
    }
  }

  &__storeName {
    margin: 10px;
    font-size: 12px;
    width: 140px;
    height: 14px;
    align-items: center;
    gap: 8px;
    display: flex;

    & > span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &.hidden {
        color: rgba(250, 250, 250, 0.4);
      }
    }

    & > svg {
      min-width: 16px;
    }

    &:first-child {
      margin-top: 0px;
    }
  }

  &__storeRow {
    display: flex;
    min-width: 130px;
    width: calc(100vw - 260px);

    &__data {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  &__cell {
    margin: 5px;
    font-size: 12px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 14px;

    &.hidden {
      color: rgba(250, 250, 250, 0.4);
    }
  }

  &__noStores {
    width: calc(100vw - 140px);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(250, 250, 250, 0.05);
    border-radius: 6px;
    height: 64px;
    margin-top: 2px;
  }

  &__hideableBlock {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 200ms;
    overflow: hidden;

    & > div {
      min-height: 0;
    }

    &.open {
      grid-template-rows: 1fr;
    }
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.mallName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
}
