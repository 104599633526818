.mainHeaderContainer {
  height: 60px;
  width: 100%;
}
.headerWrapper {
  padding: 0 52px 0 22px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  font-weight: 700;
  font-size: 15px;
}
.headerText {
  font-weight: 500;
  font-size: 16px;
  color: rgba(196, 196, 196, 1);
  padding-left: 50px;
}
.logOutContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0;
  padding-left: 12px;
  background-color: transparent;
  cursor: pointer;
}
.logOut {
  font-size: 12px;
  font-weight: 400;
  color: rgba(250, 250, 250, 1);
}
.logOutContainer:hover {
  .logOut {
    color: rgba(218, 253, 0, 1);
  }
}
.headerSelectText {
  font-size: 12px;
  font-weight: 400;
  color: rgba(121, 121, 121, 1);
}
.headerSelectText:hover {
  color: rgba(218, 253, 0, 1);
}
.languageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerBorderlessButton:hover {
  .headerSelectText {
    color: rgba(218, 253, 0, 1);
  }
}
.headerBorderlessButton {
  background-color: transparent;
  border: 0px;
  cursor: pointer;
}
.hideTextSelect {
  & text {
    display: none !important;
  }
}
.fullScreen {
  cursor: pointer;
  margin-top: 2px;

  &:hover * {
    fill: rgba(218, 253, 0, 1);
    stroke: rgba(218, 253, 0, 1);
  }
}
