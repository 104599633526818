.AdminTablesController {
  display: flex;
  gap: 16px;

  & * {
    color: #fafafa66;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;

    &.selected {
      color: white;
    }

    &:hover {
      color: #dafd00;
    }
  }
}
