.AEFMS {
  width: 100%;
  margin-bottom: 8px;

  &__name {
    font-size: 12px;
    font-weight: 300;
    color: #fafafad6;
    margin-bottom: 8px;
  }
}
