.YearPicker {
  & * {
    background-color: rgb(18, 18, 18) !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  &.ant-select-open * {
    color: #dafd00 !important;
    fill: #dafd00 !important;
  }
  & .ant-select-selection-item {
    font-size: 12px;
  }
}
