.TableController {
  position: fixed;
  top: 0;
  height: calc(100vh - 12px);
  width: 62px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 120;
  align-items: center;
  justify-content: center;
  background-color: rgb(18, 18, 18);
  right: 0;
  bottom: 0;

  & > * {
    cursor: pointer;

    &.active * {
      stroke: #dafd00;
    }

    &:not(.disabled):hover * {
      stroke: #dafd00;
    }

    &.disabled {
      opacity: 0.4;
    }
  }
}
