.AdminEditFormInput {
  width: 100%;
  margin-bottom: 8px;

  &__name {
    font-size: 12px;
    font-weight: 300;
    color: #fafafad6;
    margin-bottom: 8px;
  }

  &__input {
    width: 100%;
    height: 32px;

    &.MuiTextField-root {
      border-bottom: none;
    }

    & .MuiInputBase-root {
      height: 32px;
    }

    & input {
      width: 100%;
      height: 100%;
      color: #fafafa;
      padding: 4px 12px;
      font-size: 12px;
      border: 1px solid rgb(62, 62, 62);
      border-radius: 4px;
      height: 22px;
    }

    & *:after,
    & *:before {
      display: none;
    }
  }

  &__error {
    color: #d92828;
    font-size: 8px;
    font-weight: 300;
    line-height: 10px;
  }
}
