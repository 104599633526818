.AdminFormDate {
  display: flex;
  width: 261px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  &__name {
    font-size: 12px;
    font-weight: 300;
  }

  &__date-input {
    width: 144px;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid grey !important;

    background-color: rgb(18, 18, 18) !important;
    box-shadow: none !important;
    font-family: 'Inter', sans-serif !important;
    padding: 4px 2px !important;

    & * {
      background-color: rgb(18, 18, 18) !important;
      border-color: transparent !important;
      box-shadow: none !important;
      font-family: 'Inter', sans-serif !important;
    }

    & input {
      color: #fafafa !important;
      font-size: 12px !important;

      &::placeholder {
        color: grey !important;
      }
    }

    & .ant-picker-suffix,
    & .ant-picker-clear {
      margin-right: 6px;
    }
  }

  &__error {
    color: #d92828;
    font-size: 8px;
    font-weight: 300;
    line-height: 10px;
    max-width: 144px;
  }
}
