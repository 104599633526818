.TableHeader {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: rgb(18, 18, 18);
  height: 100px;
  padding-top: 4px;
  width: calc(100% + 20px);

  & > div {
    width: 100%;
    display: flex;
    position: relative;
  }

  &__column {
    min-width: 150px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: rgb(18, 18, 18);
    width: 100%;

    &.add {
      min-width: 80px;

      & svg {
        cursor: pointer;

        &:hover * {
          color: #dafd00 !important;
        }
      }
    }

    &.select {
      align-items: flex-start;
      position: absolute;
      right: 0;
      width: 58px;
      min-width: 0;
    }

    &__hide {
      cursor: pointer;

      &:hover * {
        fill: #dafd00 !important;
      }
    }

    &__name {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      gap: 6px;

      & span {
        color: rgba(250, 250, 250, 0.72);
        font-size: 12px;
        cursor: pointer;
      }
    }

    &__sort {
      cursor: pointer;
      &:hover * {
        stroke: #dafd00 !important;
      }
    }

    &__tax {
      color: rgba(250, 250, 250, 0.72);
      font-size: 12px;
      text-align: center;
      cursor: pointer;
    }
  }
  &__typeSelect {
    min-width: 84px;
    &__dropdown {
      width: 140px !important;
    }
    & * {
      background-color: rgb(18, 18, 18) !important;
      border-color: transparent !important;
      box-shadow: none !important;
    }
    &.ant-select-open * {
      color: #dafd00 !important;
      fill: #dafd00 !important;
    }
    & .ant-select-selection-item {
      font-size: 12px;
      color: rgba(250, 250, 250, 0.72);
    }
  }

  &__selectedRadio {
    min-width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid rgba(250, 250, 250, 0.72);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6px 18px;

    & > div {
      display: none;
      background: #121212;
      height: 5.4px;
      width: 5.4px;
      border-radius: 50%;
    }

    &.selected {
      background: #dafd00;
      border: 1px solid #dafd00;
      & > div {
        display: block;
      }
    }
  }
}
